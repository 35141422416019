<template>
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-6 p-0">
          <div class="login-card">
            <div class="login-main">
              <form class="theme-form">
                <h4 class="h-color">Sign In</h4>
                <p class="p-color">Enter your email and password to sign in</p>
                <div class="form-group">
                  
                  <input
                    class="form-control"
                    type="email"
                    required=""
                    placeholder="Company Email"
                    style="width: 358px; height: 38.39px; background-color: #FFFF; border-color: #D2D6DA;"
                  />
                </div>
                <div class="form-group">
                  <div class="form-input position-relative">
                    <input
                      class="form-control"
                      :type="active ? 'password' : 'text'"
                      name="login[password]"
                      required=""
                      placeholder="Password"
                      style="width: 358px; height: 38.39px; background-color:#FFFF; border-color: #D2D6DA;"
                    />
                    <div class="show-hide">
                      <span :class="active ? 'show' : 'hide'" @click.prevent="show"></span>
                    </div>
                  </div>
                </div>
                <div class="form-group mb-0">
                <div class="form-switch">
                    <input
                    type="checkbox"
                    id="switch1"
                    class="form-check-input"
                    v-model="rememberMe"
                    true-value="true"
                    false-value="false"
                    style="background-color: #E9ECEF;"
                    >
                     <label class="form-check-label" for="switch1">Remember me</label>
                   
                </div>
                <router-link to="/">
                  <br>
                  <br>
                    <button 
                    class="btn btn-primary w-110 h-30" 
                    style="background: linear-gradient(310deg, #0C2BAE 0%, #00A2F8 100%); 
                    border: none; 
                    border-radius: 8px; 
                    padding: 14px, 154.359px, 13.5938px, 153.641px;
                    box-shadow: 0px 4px 7px -1px rgba(0, 0, 0, 0.11), 0px 2px 4px -1px rgba(0, 0, 0, 0.07);
                    ">SIGN IN</button>
                </router-link>
                </div>
                <p class="mt-4 mb-0 text-center">
                  Forgot Password?
                  <router-link class="ms-2 text-gradient text-size" tag="a" to="/auth/resetpassword">
                    Reset password
                  </router-link>
                </p>
                <p class="mt-4 mb-0 text-center">
                  Don't have account?
                  <router-link class="ms-2 text-gradient text-size" tag="a" to="/auth/register">
                    Singup
                  </router-link>
                </p>
              </form>
            </div>
          </div>
        </div>

        <div class="col-xl-6 b-center bg-size p-absolute"
     :style="{
        /*'url(' + require('../../assets/images/login/jama_dragon.svg') + ')' 
          /*, 'linear-gradient(310deg, #0F2BAE 20%, #04A2F8 100%) '*/

        
         background:'linear-gradient(310deg, #0F2BAE 20%, #04A2F8 100%)', 
         
         borderRadius: '12px',
         backgroundSize: '60%',
         backgroundPosition: 'center center',
         backgroundRepeat: 'no-repeat',
         overflow: 'hidden',
         position:'relative'
       
     }">
        </div>
        <div class="col-xl-6 b-center bg-size"
     :style="{
         position:'absolute',
         background:  'linear-gradient(310deg, #0F2BAE 20%, #04A2F8 100%) ',
         borderRadius: '12px',
         backgroundSize: '100%',
         backgroundPosition: 'center center',
         backgroundRepeat: 'no-repeat',
         
     }">

        </div>
      
     
    </div>
  </div>     
</template>
  
  <script>
import Pxcard from '@/components/Pxcard.vue';

  

  export default {
    data() {
      return {
        active: true,
        rememberMe: false
      };
    },
    methods: {
      show() {
        this.active = !this.active;
      },
    },
  };
  </script>
  <style> 
.w-110{
  width: 360px;
}

.h-30{
  height:47.59px;
}

.login-main p {
  font-size: 16px;
}

.h-color{
  color:#344767;
}

/*corregir */
.p-color{
  color:#67748E;
}

input.form-control{
  background-color: #FFFF;
}

.text-size{
  font-size:16px;
}


.text-gradient{
  background-clip: text;
  -webkit-text-fill-color: transparent; /* Para navegadores basados en WebKit */
  background-image: linear-gradient(310deg, #0C2BAE 0%, #00A2F8 100%);
}

.form-switch {
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
}

.form-switch input[type="checkbox"] {
  position: relative;
  width: 40px;
  height: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-radius: 20px;
  border: none;
  transition: all 0.3s ease;
  cursor: pointer;
  z-index: 1;
}

.form-switch input[type="checkbox"]:before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  top: 1.8px;
  left: 1.8px;
  background-color: #FFFF;
  box-shadow: 0 0px 0px 0px rgba(0,168,248,1);
  transition: all 0.3s ease;
}

.form-switch input[type="checkbox"]:checked {
  border-color: #0C2BAE;
}

.form-switch input[type="checkbox"]:checked:before {
  transform: translateX(20px);
  background-color: #00A2F8;
}

</style>

