<template>
<div class="col-xxl-3 col-md-6 box-col-6">
    <div class="card">
        <div class="card-body">
            <!-- side-bar colleps block stat-->
            <div class="filter-block">
                <h4>Brand</h4>
                <ul>
                    <li>Clothing</li>
                    <li>Bags</li>
                    <li>Footwear</li>
                    <li>Watches</li>
                    <li>ACCESSORIES</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="collection-filter-block">
                <ul class="pro-services">
                    <li>
                        <div class="media">
                            <vue-feather type="truck"></vue-feather>
                            <div class="media-body">
                                <h5>Free Shipping</h5>
                                <p>Free Shipping World Wide</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="media">
                            <vue-feather type="clock"></vue-feather>
                            <div class="media-body">
                                <h5>24 X 7 Service</h5>
                                <p>Online Service For New Customer</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="media">
                            <vue-feather type="gift"></vue-feather>
                            <div class="media-body">
                                <h5>Festival Offer</h5>
                                <p>New Online Special Festival</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="media">
                            <vue-feather type="credit-card"></vue-feather>
                            <div class="media-body">
                                <h5>Online Payment</h5>
                                <p>Contrary To Popular Belief.</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- silde-bar colleps block end here-->
    </div>
</div>
</template>
