<template>
    <div>
        <Breadcrumbs main="Dashboard" title="default" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row widget-grid">
              
                <OverallBalanceCard />
                <PaperNoteCard />

            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>

import OverallBalanceCard from "./default/OverallBalanceCard.vue";
import RecentOrdersCard from "./default/RecentOrdersCard.vue";
import ActivitiesCard from "./default/ActivitiesCard.vue";
import PaperNoteCard from "./default/PaperNoteCard.vue";

export default {
    components: {
       
        OverallBalanceCard,
        RecentOrdersCard,
        ActivitiesCard,
        PaperNoteCard,
    },
};
</script>
