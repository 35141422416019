<template>
  <div>
    <!-- page-wrapper Start-->
    <div class="container-fluid">
      <div class="row ">
        <div class="col-12 p-0">
          <div class="login-card">
            <div>
              <div>
                <a class="logo">
                  <img
                    class="img-fluid for-light"
                    src="../assets/images/logo/logo.png"
                    alt="looginpage"
                  />
                  <img
                    class="img-fluid for-dark"
                    src="../assets/images/logo/logo_dark.png"
                    alt="looginpage"
                  />
                </a>
              </div>
              <div class="login-main">
                <form class="theme-form">
                  <h4>Sign In</h4>
                  <p>Enter your email and password to sign In</p>
                  <div class="form-group">
                    <label class="col-form-label">Email Address</label>
                    <input class="form-control" type="email" required="" placeholder="Company Email" v-model="user.email.value">
                    <span class="validate-error" v-if="!user.email.value || !validEmail(user.email.value) ">{{ user.email.errormsg }}</span>

                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Password</label>
                    <div class="form-input position-relative">
                      <input class="form-control" type="password" name="login[password]" required="" placeholder="Password" v-model="user.password.value">
                      <span class="validate-error" v-if="user.password.value.length < 7">{{ user.password.errormsg }}</span>
                      
                      <div class="show-hide"><span class="show">                         </span></div>
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <div class="checkbox p-0">
                      <input id="checkbox1" type="checkbox">
                      <v-switch label="Switch"></v-switch>
                      <label class="text-muted" for="checkbox1">Remember me</label>
                    </div><a class="link" href="forget-password.html">Reset Password</a>
                    <div class="text-end mt-3">
                      <button class="btn btn-primary btn-block w-100" type="submit" @click.prevent="login">Sign in</button>
                    
                    </div>
                  </div>
                  <h6 class="text-muted mt-4 or">Or Sign in with</h6>
                  <div class="social mt-4">
                    <div class="btn-showcase"><a class="btn btn-light" href="https://www.linkedin.com/login" target="_blank"><i class="txt-linkedin" data-feather="linkedin"></i> LinkedIn </a><a class="btn btn-light" href="https://twitter.com/login?lang=en" target="_blank"><i class="txt-twitter" data-feather="twitter"></i>twitter</a><a class="btn btn-light" href="https://www.facebook.com/" target="_blank"><i class="txt-fb" data-feather="facebook"></i>facebook</a></div>
                  </div>
                  <p class="mt-4 mb-0 text-center">Don't have account?<a class="ms-2" href="sign-up.html">Signup</a></p>
                </form>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- latest jquery-->
  </div>
</template>

<script>

  
  export default {
    name: 'login',
    data() {
      return {
  
        result: {email:'',password:''},
          
            user: {
                email: {
                    value: 'test@admin.com',
                    errormsg: ''
                },
                password: {
                    value: 'test@123456',
                    errormsg: ''
                }
            }
      };
    },
   
    created() {
     
    },
  
    methods: {
     
      login() {
            if (!this.user.password.value || this.user.password.value.length < 7) {
                this.user.password.errormsg = 'min length 7'
            }else{this.user.password.errormsg = ''}

            if (!this.user.email.value) {
                this.user.email.errormsg = 'empty not allowed'
            } else if (!this.validEmail(this.user.email.value)) {
              this.user.email.errormsg = 'Valid email required.'}
            else{
              this.user.email.errormsg=''
            }
             if(!this.user.email.errormsg && !this.user.password.errormsg && this.user.email.value != 'test@admin.com' || this.user.password.value != 'test@123456'){
                alert("wrong credenstials")
            }
            if(!this.user.email.errormsg && !this.user.password.errormsg && this.user.email.value == 'test@admin.com' && this.user.password.value== 'test@123456'){

                this.result ={email:this.user.email.value, password:this.user.password.value}
             
              localStorage.setItem('User',JSON.stringify({email:this.user.email.value,useer:true}))
              this.logging = true
              this.$router.push('/')
           

            }
          },
        validEmail: function (email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return re.test(email)
        }
    },
  };
</script>
