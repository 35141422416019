<template>
    <div>
      <div class="container-fluid p-0">
        <div class="row g-0">
          <div
            class="col-xl-6 p-0"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #f2f2f2;
            "
          >
            <div class="login-card">
              <div class="login-main">
                <form class="theme-form">
                  <h4 style="color:#344767;">Sign Up</h4>
                  <p style="color:#67748E">Enter your information to Sign Up</p>
                  <div class="mb-4">
                    <label class="col-6">Last Name</label>
                    <label class="col-6">Name</label>
                    <div class="row g-2">
                      <div class="col-6">
                        <input
                          style="width: 166px; height: 38.39px; background-color:#FFFF; border-color: #D2D6DA; margin-bottom: 5px;"
                          class="form-control"
                          type="text"
                          required=""
                          placeholder="Last Name"
                        />
                      </div>
                      <div class="col-6">
                        <input  
                          style="width: 166px; height: 38.39px; background-color:#FFFF; border-color: #D2D6DA; margin-bottom: 5px;"
                          class="form-control"
                          type="text"
                          required=""
                          placeholder="Name"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="mb-2">
                    <label class="col-6">Company Email</label>
                    <input
                      style="width: 358px; height: 38.39px; background-color:#FFFF; border-color: #D2D6DA; margin-bottom: 5px;"
                      class="form-control"
                      type="email"
                      required=""
                      placeholder="Company Email"
                    />
                  </div>
                  <div class="mb-2">
                    <label class="col-6">Company Name</label>
                    <input
                      style="width: 358px; height: 38.39px; background-color:#FFFF; border-color: #D2D6DA; margin-bottom: 5px;"
                      class="form-control"
                      type="email"
                      required=""
                      placeholder="Company Name"
                    />
                  </div>
                  <div class="mb-2">
                    <label class="col-6">Password</label>
                    <div class="form-input position-relative">
                      <input
                        style="width: 358px; height: 38.39px; background-color:#FFFF; border-color: #D2D6DA; margin-bottom: 5px;"
                        class="form-control"
                        :type="active ? 'password' : 'text'"
                        name="login[password]"
                        required=""
                        placeholder="Password"
                      />
                      <div class="show-hide">
                        <span
                          :class="active ? 'show' : 'hide'"
                          @click.prevent="show"
                        ></span>
                      </div>
                    </div>
                  </div>
                  <div class="mb-2">
                    <label class="col-6">Confirm Password</label>
                    <div class="form-input position-relative">
                      <input
                        style="width: 358px; height: 38.39px; background-color:#FFFF; border-color: #D2D6DA; margin-bottom: 5px;"
                        class="form-control"
                        :type="active ? 'password' : 'text'"
                        name="confirm[password]"
                        required=""
                        placeholder="Confirm Password"
                      />
                      <div class="show-hide">
                        <span
                          :class="active ? 'show' : 'hide'"
                          @click.prevent="show"
                        ></span>
                      </div>
                    </div>
                  </div>
  
                  <div class="form-group mb-2">
                    <div class="checkbox p-0">

                      <input id="checkbox1" type="checkbox" style="margin-bottom: 5px" />
                      <label class="text-muted" for="checkbox1">
                            I agree the<a class="ms-2" href="#">Terms and
                        Conditions</a></label
                      >
                    </div>
                    <button class="justify-content: center btn btn-primary btn-block"
                    style="width: 360px; 
                           height: 40.8px;
                           border: none;
                           left: calc(50% - 360px/2);
                           top: calc(50% - 40.8px/2 + 207.54px);
                           background: linear-gradient(310deg, #0C2BAE 0%, #00A2F8 100%);
                           box-shadow: 0px 4px 7px -1px rgba(0, 0, 0, 0.11), 0px 2px 4px -1px rgba(0, 0, 0, 0.07);
                           border-radius: 8px;
                           font-size: 12px;">
                           
                      SIGN UP
                    </button>
                  </div>
                  <p class="justify-content center mt-4 mb-0" style="text-align: center;">
                    Already have an account?
                  </p>
                  <div class="d-flex justify-content-center">
                  <router-link class="ms-2" tag="a" to="/auth/login">
                    Sign In
                  </router-link>
                </div>
                </form>
              
                    </div>
                </div>
            </div>
            <div class="col-xl-6 b-center bg-size" 
     :style="{

          /*url(' + require('../../assets/images/login/jama_dragon.svg') + ')',*/
         backgroundImage: 'linear-gradient(310deg, #0F2BAE 20%, #04A2F8 100%) ',
         borderRadius: '12px',
         backgroundSize: '100%',
         backgroundPosition: 'center center',
         backgroundRepeat: 'no-repeat'
         
     }">
</div>
         </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            active: true
        }
    },
    methods: {
        show() {
            this.active = !this.active
        }
    }
}
</script>
<style> 
.col-6{
    color:#344767;
}

</style> 
