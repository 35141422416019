<template>
  <Breadcrumbs main="Ecommerce" title="Paymen Details" />
        <!-- Container-fluid starts-->
        <div class="container-fluid credit-card">
          <div class="row">
            <!-- Individual column searching (text inputs) Starts-->
              <CreditCard/>
            <!-- Individual column searching (text inputs) Ends-->
            <!-- Debit Card Starts-->
            <DebitCard/>
            <!-- Debit Card Ends-->
            <!-- COD Starts-->
            <COD/>
            <!-- COD Ends-->
            <!-- EMI Starts-->
            <EMI/>
            <!-- EMI Ends-->
            <!-- NET Banking Starts-->
            <NetBanking/>
            <!-- EMI Ends-->
            <!-- Container-fluid Ends-->
          </div>
        </div>
        <!-- Container-fluid Ends-->
      
</template>
<script>
import CreditCard from './CreditCard.vue';
import DebitCard from './DebitCard.vue';
import COD from './COD.vue';
import EMI from './EMI.vue';
import NetBanking from './NetBanking.vue';
    export default{
        name: 'paymentDetails',
        components:{
          CreditCard,
          DebitCard,
          COD,
          EMI,
          NetBanking
        }
    }
</script>