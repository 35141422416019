<template>
    <div class="col-sm-12 col-md-6">
                <div class="card">
                    <div class="card-header">
                        <h5>Pills with Icon</h5>
                    </div>
                    <div class="card-body">
                        <a class="badge rounded-pill badge-primary p-2" href="#"><i data-feather="dollar-sign">
                                <vue-feather type="dollar-sign"></vue-feather>
                            </i></a>
                        <a class="badge rounded-pill badge-secondary p-2" href="#"><i data-feather="headphones">
                                <vue-feather type="headphones"></vue-feather>
                            </i></a>
                        <a class="badge rounded-pill badge-success p-2" href="#"><i data-feather="link">
                                <vue-feather type="link"></vue-feather>
                            </i></a>
                        <a class="badge rounded-pill badge-info p-2" href="#"><i data-feather="github">
                                <vue-feather type="github"></vue-feather>
                            </i></a>
                        <a class="badge rounded-pill badge-warning p-2" href="#"><i data-feather="award">
                                <vue-feather type="award"></vue-feather>
                            </i></a>
                        <a class="badge rounded-pill badge-danger p-2" href="#"><i data-feather="activity">
                                <vue-feather type="activity"></vue-feather>
                            </i></a>
                        <a class="badge rounded-pill badge-light text-dark p-2" href="#"><i data-feather="heart">
                                <vue-feather type="heart"></vue-feather>
                            </i></a>
                        <a class="badge rounded-pill badge-dark p-2" href="#"><i data-feather="mail">
                                <vue-feather type="mail"></vue-feather>
                            </i></a>
                    </div>
                </div>
            </div>
</template>