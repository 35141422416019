<template>
<Breadcrumbs main="Ecommerce" title="Product" />
<!-- Container-fluid starts-->
<!-- Container-fluid starts-->
<topPage />

<!-- Container-fluid Ends-->
</template>

<script>
import topPage from './toppage.vue'

export default {
    name: 'indexProduct',
    components: {
        topPage
    }
}
</script>
